import { AxiosError } from 'axios';
import { StateCreator } from 'zustand';

import { fetchUsers } from 'api/api';

export interface IUserSlice {
  users: IUser[];
  user: IUser;
  limitUser: number;
  skipUser: number;
  totalUser: number;
  userStatus: 'fulfilled' | 'pending' | 'rejected' | 'idle';
  errorMessageUser: string[];
  successMessageUser: string;
  getUsersHandler: () => void;
  getUserHandler: (_id: number) => void;
  deleteUserHandler: (_id: number) => void;
}

export const userSlice: StateCreator<IUserSlice, [], [], IUserSlice> = (
  set,
  get
) => ({
  users: [],
  user: null,
  limitUser: 0,
  skipUser: 0,
  totalUser: 0,
  userStatus: 'idle',
  errorMessageUser: [],
  successMessageUser: '',
  getUsersHandler: async () => {
    try {
      set({ userStatus: 'pending', errorMessageUser: [] });
      const { users, limit, skip, total } = await fetchUsers();

      if (users?.length > 0) {
        set({
          userStatus: 'fulfilled',
          users,
          limitUser: limit,
          skipUser: skip,
          totalUser: total,
        });
      } else {
        set({
          userStatus: 'fulfilled',
          users: [],
          limitUser: 0,
          skipUser: 0,
          totalUser: 0,
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          userStatus: 'rejected',
          errorMessageUser: error.response?.data.message,
        });
      }
    } finally {
      if (get().userStatus === 'rejected') {
        setTimeout(() => {
          set({ userStatus: 'idle' });
        }, 4000);
      }
    }
  },
  getUserHandler: async () => {},
  deleteUserHandler: async () => {},
});

import { Button, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { HiPencil, HiTrash } from 'react-icons/hi';

import { useStore } from 'store/store';

import CategoryForm from './CategoryForm';

const CategoryPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    categories,
    categoryStatus,
    getCategories,
    deleteCategory,
    addToEditCategory,
    successMessageCategory,
    errorMessageCategory,
  } = useStore((state) => state);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (categoryStatus === 'fulfilled') {
      if (successMessageCategory) {
        setTimeout(() => {
          toast.success(successMessageCategory, { duration: 1500 });
        }, 500);
      }
    }

    if (categoryStatus === 'rejected') {
      errorMessageCategory?.forEach((error) => toast.error(error), {
        duration: 1500,
      });
    }
  }, [categoryStatus, errorMessageCategory, successMessageCategory]);

  const editCategoryHandler = (category: ICategory) => {
    onOpen();
    addToEditCategory(category);
  };

  const deleteCategoryHandler = (categoryId: number) => {
    deleteCategory(categoryId);
  };

  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />
      <div className='m-4 mt-3 grid h-full grid-cols-1 gap-5'>
        <div className='col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2'>
          <div className='flex flex-col gap-y-6'>
            <section className='mt-8 flex flex-col gap-y-6'>
              <div className='flex flex-row items-center justify-between'>
                <h4 className='ml-1 text-2xl font-bold text-navy-700 dark:text-white'>
                  All Categories
                </h4>
                <Button colorScheme={'blue'} onClick={onOpen}>
                  {' '}
                  + Create Category
                </Button>
              </div>
              <ul className='flex flex-col gap-y-6'>
                {categories?.map((category) => (
                  <li
                    key={category?.id}
                    className='w-full rounded-md ring-1 ring-offset-blueSecondary'
                  >
                    <div className='flex flex-row items-center justify-between p-4'>
                      <p className='text-lg font-medium dark:text-white'>
                        {category?.category_name}
                      </p>
                      <div className='flex flex-row items-center gap-x-4'>
                        <button
                          onClick={editCategoryHandler.bind(null, category)}
                        >
                          <HiPencil className='text-xl text-gray-800 dark:text-gray-100' />
                        </button>
                        <button
                          onClick={deleteCategoryHandler.bind(
                            null,
                            category?.id
                          )}
                        >
                          <HiTrash className='text-xl text-red-600 dark:text-red-400' />
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </div>
      </div>
      <CategoryForm onClose={onClose} isOpen={isOpen} />
    </>
  );
};

export default CategoryPage;

import { useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';

import { useStore } from 'store/store';

import UserTable from './components/UserTable';

const UserPage = () => {
  const { getUsersHandler, users, userStatus, errorMessageUser } = useStore();

  useEffect(() => {
    getUsersHandler();
  }, [getUsersHandler]);

  useEffect(() => {
    if (userStatus === 'rejected') {
      errorMessageUser?.forEach((error) =>
        toast.error(error, { duration: 1500 })
      );
    }
  }, [errorMessageUser, userStatus]);

  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />
      {userStatus === 'pending' && (
        <div className='text-center font-semibold'>Loading...</div>
      )}
      {userStatus === 'fulfilled' && <UserTable tableData={users} />}
    </>
  );
};

export default UserPage;

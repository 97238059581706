import { createJSONStorage, persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { authSlice, type IAuthSlice } from './authSlice';
import { categorySlice, type ICategorySlice } from './categorySlice';
import { type IOrderSlice, orderSlice } from './orderSlice';
import { type IProductSlice, productSlice } from './productSlice';
import { type IUserSlice, userSlice } from './userSlice';

export const useStore = createWithEqualityFn<
  IAuthSlice & IProductSlice & ICategorySlice & IUserSlice & IOrderSlice
>()(
  persist(
    (...a) => ({
      ...authSlice(...a),
      ...productSlice(...a),
      ...categorySlice(...a),
      ...userSlice(...a),
      ...orderSlice(...a),
    }),
    {
      name: 'shop-storage',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        isAuth: state.isAuth,
        accessToken: state.accessToken,
      }),
    }
  ),
  shallow
);

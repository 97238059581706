import React from 'react';
import { FiAlignJustify, FiSearch } from 'react-icons/fi';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import NavbarDropdown from './NavbarDropdown';

const Navbar = (props: { onOpenSideNav: () => void; brandText: string }) => {
  const { onOpenSideNav, brandText } = props;
  const [darkMode, setDarkMode] = React.useState(false);

  return (
    <nav className='sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between gap-x-4 rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:ml-3'>
      <div className='ml-4'>
        <div className='h-6 w-full pt-1'>
          <a
            className='text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white'
            href=' '
          >
            Admin
            <span className='mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white'>
              /
            </span>
          </a>
          <Link
            className='text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white'
            to='#'
          >
            {brandText}
          </Link>
        </div>
        <p className='shrink text-[33px] capitalize text-navy-700 dark:text-white'>
          <Link
            to='#'
            className='font-bold capitalize hover:text-navy-700 dark:hover:text-white'
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className='relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2'>
        <div className='flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]'>
          <p className='pl-3 pr-2 text-xl'>
            <FiSearch className='h-4 w-4 text-gray-400 dark:text-white' />
          </p>
          <input
            type='text'
            placeholder='Search...'
            className='block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit'
          />
        </div>
        <button
          className='flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden'
          onClick={onOpenSideNav}
        >
          <FiAlignJustify className='h-5 w-5' />
        </button>
        <button
          className='cursor-pointer text-gray-600'
          onClick={() => {
            if (darkMode) {
              document.body.classList.remove('dark');
              setDarkMode(false);
            } else {
              document.body.classList.add('dark');
              setDarkMode(true);
            }
          }}
        >
          {darkMode ? (
            <RiSunFill className='h-4 w-4 text-gray-600 dark:text-white' />
          ) : (
            <RiMoonFill className='h-4 w-4 text-gray-600 dark:text-white' />
          )}
        </button>
        {/* Profile & Dropdown */}
        <NavbarDropdown />
      </div>
    </nav>
  );
};

export default Navbar;

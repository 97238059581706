import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Select,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';

import { useStore } from 'store/store';

import useInputState from 'hooks/useInputState';

interface IProductFormProps {
  onClose: () => void;
  isOpen: boolean;
}
const ProductForm = ({ onClose, isOpen }: IProductFormProps) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const { input, setInput, onChangeInputHandler } = useInputState({
    inputState: {
      title: '',
      description: '',
      price: 0,
      category_id: 0,
      stock_quantity: 0,
      discount_percentage: 0,
    },
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const {
    title,
    description,
    price,
    category_id,
    stock_quantity,
    discount_percentage,
  } = input;

  const {
    getCategories,
    categories,
    createProductHandler,
    updateProductHandler,
    productStatus,
    errorMessageProduct,
    successMessageProduct,
    isEdit,
    product,
    resetEditProduct,
  } = useStore((state) => state);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (productStatus === 'fulfilled') {
      setInput({
        title: '',
        description: '',
        price: 0,
        category_id: 0,
        stock_quantity: 0,
        discount_percentage: 0,
      });
      setImageFile({} as File);
      setImagePreview(null);
      if (successMessageProduct) {
        setTimeout(() => {
          toast.success(successMessageProduct, { duration: 1500 });
        }, 500);
      }
    }

    if (productStatus === 'rejected') {
      errorMessageProduct?.forEach((error) =>
        toast.error(error, { duration: 1500 })
      );
    }
  }, [errorMessageProduct, productStatus, setInput, successMessageProduct]);

  useEffect(() => {
    if (isEdit && product) {
      setInput({
        title: product.title,
        description: product.description,
        price: product.price,
        category_id: product.category_id,
        stock_quantity: product.stock_quantity,
        discount_percentage: product.discount_percentage,
      });
    }
  }, [setInput, isEdit, product]);

  const onChangeImageHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];

    if (!file) return;
    if (file.size > 2 * 1024 * 1024) {
      toast.error('Image size should be less than 2MB', { duration: 2000 });
      setImageFile(null);
      setImagePreview(null);
      return;
    }
    setImageFile(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const addProductHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!title || !description || !price || !category_id || !stock_quantity) {
      toast.error('Please fill in all fields', { duration: 1500 });
      return;
    }

    if (isEdit) {
      updateProductHandler({
        id: product?.id,
        title,
        description,
        price,
        category_id,
        stock_quantity,
        discount_percentage,
        image_url: imageFile ? imageFile : product?.image_url,
      });
      resetEditProduct();
    } else {
      if (!imageFile) {
        toast.error('Please select an image', { duration: 1500 });
        return;
      }

      createProductHandler({
        title,
        description,
        price,
        category_id,
        stock_quantity,
        discount_percentage,
        image_url: imageFile,
      });
    }

    onClose();
  };

  const onCloseModalHandler = () => {
    onClose();
    setInput({
      title: '',
      description: '',
      price: 0,
      category_id: 0,
      stock_quantity: 0,
      discount_percentage: 0,
    });
    setImageFile({} as File);
    setImagePreview(null);
    resetEditProduct();
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onCloseModalHandler}
      size='xl'
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isEdit ? 'Edit Product' : 'Add New Product'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form
            onSubmit={addProductHandler}
            id='form-product'
            className='flex flex-col gap-y-4'
          >
            <FormControl className='flex flex-col gap-y-2'>
              <FormLabel
                fontSize='lg'
                fontWeight='medium'
                textColor='gray.700'
                className='dark:text-white'
              >
                Product Name
              </FormLabel>
              <Input
                width='100%'
                padding='.6rem'
                rounded='.5rem'
                variant='outline'
                bgColor='gray.50'
                name='title'
                type='text'
                value={title}
                onChange={onChangeInputHandler}
                required
                ref={initialRef}
              />
              <FormHelperText
                fontSize='sm'
                fontWeight='medium'
                textColor='gray.400'
                className='dark:text-gray-600'
              >
                Product name min 5 characters long
              </FormHelperText>
            </FormControl>
            <FormControl className='flex flex-col gap-y-2'>
              <FormLabel
                fontSize='lg'
                fontWeight='medium'
                textColor='gray.700'
                className='dark:text-white'
              >
                Product Description
              </FormLabel>
              <Input
                width='100%'
                height='100%'
                padding='.6rem'
                rounded='.5rem'
                variant='outline'
                bgColor='gray.50'
                as='textarea'
                name='description'
                type='text'
                value={description}
                onChange={onChangeInputHandler}
                required
              />
              <FormHelperText
                fontSize='sm'
                fontWeight='medium'
                textColor='gray.400'
                className='dark:text-gray-600'
              >
                Product description min 10 characters long
              </FormHelperText>
            </FormControl>
            <FormControl className='flex flex-col gap-y-2'>
              <FormLabel
                fontSize='lg'
                fontWeight='medium'
                textColor='gray.700'
                className='dark:text-white'
              >
                Category
              </FormLabel>
              <Select
                placeholder='Select Category'
                variant='outline'
                bgColor='gray.50'
                name='category_id'
                value={category_id}
                onChange={onChangeInputHandler}
                required
              >
                {categories?.map((category) => (
                  <option key={category.id} value={category.id.toString()}>
                    {category.category_name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl className='flex flex-col gap-y-2'>
              <FormLabel
                fontSize='lg'
                fontWeight='medium'
                textColor='gray.700'
                className='dark:text-white'
              >
                Price
              </FormLabel>
              <NumberInput
                max={99999999}
                min={1}
                step={1}
                value={price}
                isRequired
                name='price'
              >
                <NumberInputField
                  width='100%'
                  padding='.6rem'
                  rounded='.5rem'
                  bgColor='gray.50'
                  onChange={onChangeInputHandler}
                />
                {/* <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper> */}
              </NumberInput>
            </FormControl>
            <FormControl className='flex flex-col gap-y-2'>
              <FormLabel
                fontSize='lg'
                fontWeight='medium'
                textColor='gray.700'
                className='dark:text-white'
              >
                Discount Percentage
              </FormLabel>
              <NumberInput
                max={100}
                min={1}
                step={1}
                isRequired
                name='discount_percentage'
                value={discount_percentage}
              >
                <NumberInputField
                  width='100%'
                  padding='.6rem'
                  rounded='.5rem'
                  bgColor='gray.50'
                  onChange={onChangeInputHandler}
                />
                {/* <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper> */}
              </NumberInput>
              <FormHelperText
                fontSize='sm'
                fontWeight='medium'
                textColor='gray.400'
                className='dark:text-gray-600'
              >
                Discount Percentage default is 0%
              </FormHelperText>
            </FormControl>
            <FormControl className='flex flex-col gap-y-2'>
              <FormLabel
                fontSize='lg'
                fontWeight='medium'
                textColor='gray.700'
                className='dark:text-white'
              >
                Stock Quantity
              </FormLabel>
              <NumberInput
                max={1000}
                min={1}
                step={1}
                isRequired
                name='stock_quantity'
                value={stock_quantity}
              >
                <NumberInputField
                  width='100%'
                  padding='.6rem'
                  rounded='.5rem'
                  bgColor='gray.50'
                  onChange={onChangeInputHandler}
                />
                {/* <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper> */}
              </NumberInput>
            </FormControl>
            {isEdit && product?.image_url && (
              <Box boxSize='sm' className='flex flex-col gap-y-2'>
                <FormLabel
                  fontSize='lg'
                  fontWeight='medium'
                  textColor='gray.700'
                  className='dark:text-white'
                >
                  Current Image
                </FormLabel>
                <Image
                  src={product?.image_url as string}
                  alt={product?.title}
                  boxSize='sm'
                  rounded='md'
                  objectFit='cover'
                  loading='lazy'
                />
              </Box>
            )}
            <FormControl className='flex flex-col gap-y-2'>
              <FormLabel
                fontSize='lg'
                fontWeight='medium'
                textColor='gray.700'
                className='dark:text-white'
              >
                Image
              </FormLabel>
              <Input
                width='fit-content'
                variant='ghost'
                type='file'
                onChange={onChangeImageHandler}
                accept='.jpg, .jpeg, .png, .webp'
                required={product?.image_url ? false : true}
              />
              <FormHelperText
                fontSize='sm'
                fontWeight='medium'
                textColor='gray.400'
                className='dark:text-gray-600'
              >
                Product image max 2MB
              </FormHelperText>
            </FormControl>
            {imagePreview && (
              <Image
                src={imagePreview}
                alt='preview'
                boxSize='sm'
                rounded='md'
                objectFit='cover'
                loading='lazy'
                className='mt-2'
              />
            )}
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            size='md'
            type='submit'
            width='fit-content'
            paddingY='.7rem'
            paddingX='3rem'
            colorScheme='blue'
            mr={3}
            isDisabled={productStatus === 'pending'}
            form='form-product'
          >
            Submit
          </Button>
          <Button
            onClick={onCloseModalHandler}
            type='button'
            variant='ghost'
            colorScheme='red'
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProductForm;

import { AxiosError } from 'axios';
import { StateCreator } from 'zustand';

import {
  deleteProduct,
  fetchProducts,
  postProduct,
  updateProduct,
} from 'api/api';

export interface IProductSlice {
  products: IProduct[];
  product: IProduct | null;
  limitProduct: number;
  skipProduct: number;
  totalProduct: number;
  isEdit: boolean;
  productStatus: 'fulfilled' | 'pending' | 'rejected' | 'idle';
  successMessageProduct: string;
  errorMessageProduct: string[];
  addToEditProduct: (_product: IProduct) => void;
  resetEditProduct: () => void;
  getProductsHandler: () => void;
  createProductHandler: (_product: IProduct) => void;
  updateProductHandler: (_product: IProduct) => void;
  deleteProductHandler: (_id: number) => void;
}

export const productSlice: StateCreator<
  IProductSlice,
  [],
  [],
  IProductSlice
> = (set, get) => ({
  products: [],
  product: null,
  limitProduct: 0,
  skipProduct: 0,
  totalProduct: 0,
  isEdit: false,
  productStatus: 'idle',
  successMessageProduct: '',
  errorMessageProduct: [],
  getProductsHandler: async () => {
    try {
      set({
        productStatus: 'pending',
        errorMessageProduct: [],
        successMessageProduct: '',
      });

      const { products, limit, skip, total } = await fetchProducts();

      if (products.length > 0) {
        set({
          productStatus: 'fulfilled',
          products: products,
          limitProduct: limit,
          skipProduct: skip,
          totalProduct: total,
        });
      } else {
        set({
          productStatus: 'fulfilled',
          products: [],
          limitProduct: 0,
          skipProduct: 0,
          totalProduct: 0,
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          productStatus: 'rejected',
          errorMessageProduct: error.response?.data.message,
        });
      }
    } finally {
      if (
        get().productStatus === 'rejected' ||
        get().productStatus === 'fulfilled'
      ) {
        setTimeout(() => {
          set({ productStatus: 'idle' });
        }, 4000);
      }
    }
  },
  createProductHandler: async (product) => {
    try {
      set({
        productStatus: 'pending',
        errorMessageProduct: [],
        successMessageProduct: '',
      });

      const { message, product: newProduct } = await postProduct(product);
      if (newProduct?.id) {
        set((state) => ({
          productStatus: 'fulfilled',
          products: [...state.products, newProduct],
          successMessageProduct: message,
        }));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          productStatus: 'rejected',
          errorMessageProduct: error.response?.data.message,
        });
      }
    } finally {
      if (
        get().productStatus === 'rejected' ||
        get().productStatus === 'fulfilled'
      ) {
        setTimeout(() => {
          set({ productStatus: 'idle' });
        }, 4000);
      }
    }
  },
  updateProductHandler: async (product) => {
    try {
      set({
        productStatus: 'pending',
        errorMessageProduct: [],
        successMessageProduct: '',
      });

      const { message, product: updatedProduct } = await updateProduct(product);

      if (updatedProduct?.id) {
        set((state) => ({
          productStatus: 'fulfilled',
          products: state.products.map((p) =>
            p.id === updatedProduct.id ? updatedProduct : p
          ),
          successMessageProduct: message,
        }));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          productStatus: 'rejected',
          errorMessageProduct: error.response?.data.message,
        });
      }
    } finally {
      if (
        get().productStatus === 'rejected' ||
        get().productStatus === 'fulfilled'
      ) {
        setTimeout(() => {
          set({ productStatus: 'idle', successMessageProduct: '' });
        }, 4000);
      }
    }
  },
  deleteProductHandler: async (id) => {
    try {
      set({
        productStatus: 'pending',
        errorMessageProduct: [],
        successMessageProduct: '',
      });

      const { message, status } = await deleteProduct(id);

      if (status === 'success') {
        set((state) => ({
          productStatus: 'fulfilled',
          products: state.products.filter((product) => product.id !== id),
          successMessageProduct: message,
        }));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          productStatus: 'rejected',
          errorMessageProduct: error.response?.data.message,
        });
      }
    } finally {
      if (
        get().productStatus === 'rejected' ||
        get().productStatus === 'fulfilled'
      ) {
        setTimeout(() => {
          set({ productStatus: 'idle' });
        }, 4000);
      }
    }
  },
  addToEditProduct: (product) => {
    set({ product: product, isEdit: true });
  },
  resetEditProduct: () => {
    set({ product: null, isEdit: false });
  },
});

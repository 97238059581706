import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import Card from 'components/card';

const columnHelper = createColumnHelper<IUser>();
const columns = [
  columnHelper.accessor('email', {
    id: 'email',
    header: () => (
      <p className='text-sm font-bold text-gray-600 dark:text-white'>EMAIL</p>
    ),
    cell: (info) => (
      <div className='flex items-center'>
        <p className='ml-3 text-sm font-bold text-navy-700 dark:text-white'>
          {info.getValue()}
        </p>
      </div>
    ),
  }),
  columnHelper.accessor('first_name', {
    id: 'first_name',
    header: () => (
      <p className='text-sm font-bold text-gray-600 dark:text-white'>
        FIRST NAME
      </p>
    ),
    cell: (info) => (
      <p className='text-sm font-bold text-navy-700 dark:text-white'>
        {info.getValue() || '-'}
      </p>
    ),
  }),
  columnHelper.accessor('last_name', {
    id: 'last_name',
    header: () => (
      <p className='text-sm font-bold text-gray-600 dark:text-white'>
        LAST NAME
      </p>
    ),
    cell: (info) => (
      <p className='text-sm font-bold text-navy-700 dark:text-white'>
        {info.getValue() || '-'}
      </p>
    ),
  }),
  columnHelper.accessor('address', {
    id: 'address',
    header: () => (
      <p className='text-sm font-bold text-gray-600 dark:text-white'>ADDRESS</p>
    ),
    cell: (info) => (
      <p className='text-sm font-bold text-navy-700 dark:text-white'>
        {info.getValue() || '-'}
      </p>
    ),
  }),
  columnHelper.accessor('phone_number', {
    id: 'phone_number',
    header: () => (
      <p className='text-sm font-bold text-gray-600 dark:text-white'>
        PHONE NUMBER
      </p>
    ),
    cell: (info) => (
      <p className='text-sm font-bold text-navy-700 dark:text-white'>
        {info.getValue() || '-'}
      </p>
    ),
  }),
  columnHelper.accessor('created_at', {
    id: 'created_at',
    header: () => (
      <p className='text-sm font-bold text-gray-600 dark:text-white'>
        REGISTERED AT
      </p>
    ),
    cell: (info) => (
      <p className='text-sm font-bold text-navy-700 dark:text-white'>
        {new Date(info.getValue()).toDateString()}
      </p>
    ),
  }),
];

const UserTable = ({ tableData }: Readonly<{ tableData: IUser[] }>) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  // eslint-disable-next-line no-unused-vars
  const [data, _setData] = useState(tableData);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <div className='m-4 mt-3 grid h-full grid-cols-1 gap-5'>
      <div className='col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2'>
        <div className='flex flex-col gap-y-6'>
          <h4 className='ml-1 text-2xl font-bold text-navy-700 dark:text-white'>
            All Registered User
          </h4>

          <Card extra={'w-full h-full p-4'}>
            <div className='mt-8 overflow-x-scroll xl:overflow-x-hidden'>
              <table className='w-full'>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className='!border-px !border-gray-400'
                    >
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className='cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start'
                          >
                            <div className='items-center justify-between text-xs text-gray-200'>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table
                    .getRowModel()
                    .rows.slice(0, 5)
                    .map((row) => {
                      return (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <td
                                key={cell.id}
                                className='min-w-[150px] border-white/0 py-3  pr-4'
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default UserTable;

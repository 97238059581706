import { AxiosError } from 'axios';
import { StateCreator } from 'zustand';

import { refreshToken, signIn, signOut, signUp } from 'api/api';

export interface IAuthSlice {
  isAuth: boolean;
  accessToken: string;
  authStatus: 'fulfilled' | 'pending' | 'rejected' | 'idle';
  successMessageAuth: string;
  errorMessageAuth: string[];
  signInHandler: (_user: ISignIn) => void;
  signUpHandler: (_newUser: ISignUp) => void;
  signOutHandler: () => void;
  refreshHandler: () => Promise<string>;
}

export const authSlice: StateCreator<IAuthSlice, [], [], IAuthSlice> = (
  set,
  get
) => ({
  isAuth: false,
  accessToken: '',
  authStatus: 'idle',
  successMessageAuth: '',
  errorMessageAuth: [],
  signUpHandler: async ({ email, password, confirmPassword, adminCode }) => {
    try {
      set({ authStatus: 'pending', errorMessageAuth: [] });
      const { access_token, message } = await signUp({
        email,
        password,
        confirmPassword,
        adminCode,
      });

      if (access_token) {
        set({
          isAuth: true,
          accessToken: access_token,
          authStatus: 'fulfilled',
          successMessageAuth: message,
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          authStatus: 'rejected',
          errorMessageAuth: error.response?.data.message,
        });
      }
    } finally {
      if (get().authStatus === 'rejected') {
        setTimeout(() => {
          set({ authStatus: 'idle' });
        }, 4000);
      }
    }
  },
  signInHandler: async ({ email, password }) => {
    try {
      set({ authStatus: 'pending', errorMessageAuth: [] });
      const { access_token, message } = await signIn({ email, password });
      if (access_token) {
        set({
          isAuth: true,
          accessToken: access_token,
          authStatus: 'fulfilled',
          successMessageAuth: message,
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          authStatus: 'rejected',
          errorMessageAuth: error.response?.data.message,
        });
      }
    } finally {
      if (get().authStatus === 'rejected') {
        setTimeout(() => {
          set({ authStatus: 'idle' });
        }, 4000);
      }
    }
  },
  signOutHandler: async () => {
    try {
      set({ authStatus: 'pending', errorMessageAuth: [] });
      const { message, status } = await signOut();

      if (status === 'success') {
        set({
          isAuth: false,
          accessToken: '',
          authStatus: 'idle',
          successMessageAuth: message,
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          authStatus: 'rejected',
          errorMessageAuth: error.response?.data.message,
        });
      }
    } finally {
      if (get().authStatus === 'rejected') {
        setTimeout(() => {
          set({ authStatus: 'idle' });
        }, 4000);
      }
    }
  },
  refreshHandler: async () => {
    try {
      set({ authStatus: 'pending', errorMessageAuth: [] });
      const { access_token } = await refreshToken();
      if (access_token) {
        set({ accessToken: access_token, authStatus: 'fulfilled' });
        return access_token;
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          authStatus: 'rejected',
          errorMessageAuth: error.response?.data.message,
        });
      }
    } finally {
      if (get().authStatus === 'rejected') {
        setTimeout(() => {
          set({ authStatus: 'idle' });
        }, 4000);
      }
    }
  },
});

import { useEffect } from 'react';
import { IoDocuments } from 'react-icons/io5';
import { MdBarChart } from 'react-icons/md';

import MiniCalendar from 'components/calendar/MiniCalendar';
import Widget from 'components/widget/Widget';

import NewOrder from 'pages/admin/MainDashboard/components/NewOrder';

import { useStore } from 'store/store';

import Banner from './components/Banner';
import tableDataCheck from './variables/tableDataCheck';

const DashboardPage = () => {
  const {
    getProductsHandler,
    totalProduct,
    totalCategory,
    totalUser,
    getCategories,
    getUsersHandler,
    getOrdersHandler,
  } = useStore();

  useEffect(() => {
    getProductsHandler();
    getCategories();
    getUsersHandler();
    getOrdersHandler();
  }, [getProductsHandler, getCategories, getUsersHandler, getOrdersHandler]);

  return (
    <div className='m-6 flex flex-col gap-y-4'>
      <Banner />
      <div className='grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6'>
        <Widget
          icon={<MdBarChart className='h-7 w-7' />}
          title={'Total Products'}
          subtitle={totalProduct}
        />
        <Widget
          icon={<IoDocuments className='h-6 w-6' />}
          title={'Total Categories'}
          subtitle={totalCategory}
        />
        <Widget
          icon={<IoDocuments className='h-6 w-6' />}
          title={'Total Users'}
          subtitle={totalUser}
        />
      </div>

      <div className='flex w-full flex-col gap-y-4 md:flex-row md:gap-x-4'>
        <NewOrder tableData={tableDataCheck} />
        <MiniCalendar />
      </div>
    </div>
  );
};

export default DashboardPage;

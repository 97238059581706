import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import routes from 'routes';

import Footer from 'components/footer/Footer';
import Navbar from 'components/navbar';
import Sidebar from 'components/sidebar';

export default function Admin(props: Readonly<{ [x: string]: unknown }>) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState('Main Dashboard');

  React.useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    const activeRoute = 'Main Dashboard';
    for (const element of routes) {
      if (
        window.location.href.indexOf(element.layout + '/' + element.path) !== -1
      ) {
        setCurrentRoute(element.name);
      }
    }
    return activeRoute;
  };
  // const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
  //   let activeNavbar = false;
  //   for (const element of routes) {
  //     if (window.location.href.indexOf(element.layout + element.path) !== -1) {
  //       return element.secondary;
  //     }
  //   }
  //   return activeNavbar;
  // };
  const getRoutes = (routes: RoutesType[]): React.ReactNode => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = 'ltr';
  return (
    <div className='flex h-full w-full'>
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className='h-full w-full bg-lightPrimary dark:!bg-navy-900'>
        {/* Main Content */}
        <main
          className={`h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className='h-full'>
            <Navbar
              onOpenSideNav={() => setOpen(true)}
              brandText={currentRoute}
              // secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className='mx-auto mb-auto h-full min-h-[84vh] p-2 pt-5 md:pr-2'>
              <Routes>
                {getRoutes(routes)}

                <Route
                  path='/'
                  element={<Navigate to='/admin/dashboard' replace />}
                />
              </Routes>
            </div>
            <div className='p-3'>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

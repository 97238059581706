import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useStore } from 'store/store';

export default function RequireAuth() {
  const { isAuth } = useStore((state) => state);
  const location = useLocation();

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to='/auth/sign-in' state={{ from: location.pathname }} replace />
  );
}

import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import { Navigate, Route, Routes } from 'react-router-dom';

import RequireAuth from 'components/auth/RequireAuth';

import { useStore } from 'store/store';
const App = () => {
  const { isAuth } = useStore((state) => state);

  return (
    <Routes>
      <Route
        path='auth/*'
        element={isAuth ? <Navigate to='/admin' replace /> : <AuthLayout />}
      />
      <Route element={<RequireAuth />}>
        <Route path='/' element={<Navigate to='/admin' replace />} />
        <Route path='admin/*' element={<AdminLayout />} />
      </Route>
    </Routes>
  );
};

export default App;

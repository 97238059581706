import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';

import { useStore } from 'store/store';

import useInputState from 'hooks/useInputState';

interface ICategoryFormProps {
  onClose: () => void;
  isOpen: boolean;
}

const CategoryForm = ({ onClose, isOpen }: ICategoryFormProps) => {
  const { input, setInput, onChangeInputHandler } = useInputState({
    inputState: { categoryName: '' },
  });

  const {
    category,
    isEditCategory,
    updateCategory,
    createCategory,
    resetEditCategory,
    categoryStatus,
  } = useStore();

  const { categoryName } = input;
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  useEffect(() => {
    if (categoryStatus === 'fulfilled') {
      setInput({
        categoryName: '',
      });
    }
  }, [categoryStatus, setInput]);

  useEffect(() => {
    if (isEditCategory && category) {
      setInput({
        categoryName: category?.category_name,
      });
    }
  }, [category, isEditCategory, setInput]);

  const categorySubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!categoryName || categoryName.length === 0) {
      toast.error('Please fill in all fields', { duration: 1500 });
      return;
    }

    if (isEditCategory) {
      updateCategory({ id: category?.id, category_name: categoryName });
      resetEditCategory();
    } else {
      createCategory({ category_name: categoryName });
    }

    onCloseModalHandler();
  };

  const onCloseModalHandler = () => {
    onClose();
    setInput({
      categoryName: '',
    });
    resetEditCategory();
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onCloseModalHandler}
      size='xl'
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isEditCategory ? 'Edit Category' : 'Add New Category'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form
            onSubmit={categorySubmitHandler}
            id='category-form'
            className='flex flex-col gap-y-6'
          >
            <FormControl className='flex flex-col gap-y-2'>
              <FormLabel
                fontSize='lg'
                fontWeight='medium'
                textColor='gray.700'
                className='dark:text-white'
              >
                Category Name
              </FormLabel>
              <Input
                width='100%'
                padding='.6rem'
                rounded='.5rem'
                variant='outline'
                bgColor='gray.50'
                name='categoryName'
                value={categoryName}
                onChange={onChangeInputHandler}
              />
              <FormHelperText
                fontSize='sm'
                fontWeight='medium'
                textColor='gray.400'
                className='dark:text-gray-600'
              >
                Category name min 5 characters long
              </FormHelperText>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            size='md'
            type='submit'
            width='fit-content'
            paddingY='.7rem'
            paddingX='3rem'
            colorScheme='blue'
            mr={3}
            isDisabled={categoryStatus === 'pending'}
            form='category-form'
          >
            Submit
          </Button>
          <Button
            onClick={onCloseModalHandler}
            type='button'
            variant='ghost'
            colorScheme='red'
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CategoryForm;

import { AxiosError } from 'axios';
import { StateCreator } from 'zustand';

import {
  deleteCategory,
  fetchCategories,
  postCategory,
  updateCategory,
} from 'api/api';

export interface ICategorySlice {
  categories: ICategory[];
  category: ICategory | null;
  limitCategory: number;
  skipCategory: number;
  totalCategory: number;
  isEditCategory: boolean;
  categoryStatus: 'fulfilled' | 'pending' | 'rejected' | 'idle';
  successMessageCategory: string;
  errorMessageCategory: string[];
  addToEditCategory: (_category: ICategory) => void;
  resetEditCategory: () => void;
  getCategories: () => void;
  createCategory: (_category: ICategory) => void;
  updateCategory: (_category: ICategory) => void;
  deleteCategory: (_id: number) => void;
}

export const categorySlice: StateCreator<
  ICategorySlice,
  [],
  [],
  ICategorySlice
> = (set, get) => ({
  categories: [],
  category: null,
  limitCategory: 0,
  skipCategory: 0,
  totalCategory: 0,
  isEditCategory: false,
  categoryStatus: 'idle',
  successMessageCategory: '',
  errorMessageCategory: [],
  getCategories: async () => {
    try {
      set({
        categoryStatus: 'pending',
        errorMessageCategory: [],
        successMessageCategory: '',
      });
      const { categories, limit, skip, total } = await fetchCategories();

      if (categories.length > 0) {
        set({
          categoryStatus: 'fulfilled',
          categories: categories,
          limitCategory: limit,
          skipCategory: skip,
          totalCategory: total,
        });
      } else {
        set({
          categoryStatus: 'fulfilled',
          categories: [],
          limitCategory: 0,
          skipCategory: 0,
          totalCategory: 0,
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          categoryStatus: 'rejected',
          errorMessageCategory: error.response?.data.message,
        });
      }
    }
  },
  createCategory: async (category) => {
    try {
      set({
        categoryStatus: 'pending',
        errorMessageCategory: [],
        successMessageCategory: '',
      });
      const { message, category: newCategory } = await postCategory(category);
      if (newCategory?.id) {
        set((state) => ({
          categoryStatus: 'fulfilled',
          categories: [...state.categories, newCategory],
          successMessageCategory: message,
        }));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          categoryStatus: 'rejected',
          errorMessageCategory: error.response?.data.message,
        });
      }
    } finally {
      if (
        get().categoryStatus === 'rejected' ||
        get().categoryStatus === 'fulfilled'
      ) {
        setTimeout(() => {
          set({ categoryStatus: 'idle' });
        }, 4000);
      }
    }
  },
  updateCategory: async (categoryData) => {
    try {
      set({
        categoryStatus: 'pending',
        errorMessageCategory: [],
        successMessageCategory: '',
      });

      const { category, message } = await updateCategory(categoryData);

      if (category?.id) {
        set((state) => ({
          categoryStatus: 'fulfilled',
          categories: state.categories.map((c) =>
            c.id === category.id ? category : c
          ),
          successMessageCategory: message,
        }));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          categoryStatus: 'rejected',
          errorMessageCategory: error.response?.data.message,
        });
      }
    } finally {
      if (
        get().categoryStatus === 'rejected' ||
        get().categoryStatus === 'fulfilled'
      ) {
        setTimeout(() => {
          set({ categoryStatus: 'idle' });
        }, 4000);
      }
    }
  },
  deleteCategory: async (categoryId) => {
    try {
      set({
        categoryStatus: 'pending',
        errorMessageCategory: [],
        successMessageCategory: '',
      });

      const { message, status } = await deleteCategory(categoryId);

      if (status === 'success') {
        set((state) => ({
          categoryStatus: 'fulfilled',
          categories: state.categories.filter((c) => c.id !== categoryId),
          successMessageCategory: message,
        }));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          categoryStatus: 'rejected',
          errorMessageCategory: error.response?.data.message,
        });
      }
    } finally {
      if (
        get().categoryStatus === 'rejected' ||
        get().categoryStatus === 'fulfilled'
      ) {
        setTimeout(() => {
          set({ categoryStatus: 'idle' });
        }, 4000);
      }
    }
  },
  addToEditCategory: (category) => {
    set({ category, isEditCategory: true });
  },
  resetEditCategory: () => {
    set({ category: null, isEditCategory: false });
  },
});

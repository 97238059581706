import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import { useStore } from 'store/store';

import useInputState from 'hooks/useInputState';

export default function SignUp() {
  const { input, setInput, onChangeInputHandler } = useInputState({
    inputState: { email: '', password: '', confirmPassword: '', adminCode: '' },
  });

  const { email, password, confirmPassword, adminCode } = input;

  const { signUpHandler, authStatus, errorMessageAuth } = useStore(
    (state) => state
  );

  useEffect(() => {
    if (authStatus === 'rejected') {
      errorMessageAuth?.forEach((error) =>
        toast.error(error, { duration: 1500 })
      );
      return;
    }

    if (authStatus === 'fulfilled') {
      setInput({ email: '', password: '', confirmPassword: '', adminCode: '' });
    }
  }, [authStatus, errorMessageAuth, setInput]);

  const signUpSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      toast.error('Passwords do not match', { duration: 1500 });
      return;
    }

    if (!email || !password || !confirmPassword || !adminCode) {
      toast.error('Please fill in all fields', { duration: 1500 });
      return;
    }

    signUpHandler({ email, password, confirmPassword, adminCode });
  };

  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />
      <div className='mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start'>
        <div className='mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]'>
          <h4 className='mb-2.5 text-4xl font-bold text-navy-700 dark:text-white'>
            Sign Up
          </h4>
          <p className='mb-9 ml-1 text-base text-gray-600'>
            Enter your email, password and confirm password to sign up!
          </p>
          <form
            className='flex flex-col gap-y-4'
            onSubmit={signUpSubmitHandler}
          >
            {/* Email */}
            <FormControl>
              <FormLabel fontSize='sm' fontWeight='normal'>
                Email address*
              </FormLabel>
              <Input
                type='email'
                placeholder='mail@example.com'
                name='email'
                onChange={onChangeInputHandler}
                value={email}
              />
            </FormControl>

            {/* Password */}
            <FormControl>
              <FormLabel fontSize='sm' fontWeight='normal'>
                Password*
              </FormLabel>
              <Input
                type='password'
                placeholder='Min. 8 characters'
                name='password'
                onChange={onChangeInputHandler}
                value={password}
              />
            </FormControl>

            {/* Confirm Password */}
            <FormControl>
              <FormLabel fontSize='sm' fontWeight='normal'>
                Confirm Password*
              </FormLabel>
              <Input
                type='password'
                placeholder='Min. 8 characters'
                name='confirmPassword'
                onChange={onChangeInputHandler}
                value={confirmPassword}
              />
            </FormControl>

            {/* Admin Code field */}
            <FormControl>
              <FormLabel fontSize='sm' fontWeight='normal'>
                Admin Code*
              </FormLabel>
              <Input
                type='password'
                placeholder='Enter admin code'
                name='adminCode'
                onChange={onChangeInputHandler}
                value={adminCode}
              />
            </FormControl>
            <button
              type='submit'
              className='linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'
            >
              Sign Up
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

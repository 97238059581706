const Order = () => {
  return (
    <div className='m-4 mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3'>
      <div className='col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2'>
        <div className='flex flex-col gap-y-6'>
          <div className='flex flex-row items-center justify-between'>
            <h4 className='ml-1 text-2xl font-bold text-navy-700 dark:text-white'>
              All Orders
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;

import Dropdown from 'components/dropdown';

import { useStore } from 'store/store';

import avatar from 'assets/img/avatars/avatar4.png';

const NavbarDropdown = () => {
  const { signOutHandler } = useStore((state) => state);

  const signOutAdminHandler = () => {
    signOutHandler();
    useStore.persist.clearStorage();
    window.location.reload();
  };

  return (
    <Dropdown
      button={
        <img className='h-10 w-10 rounded-full' src={avatar} alt='Admin' />
      }
      classNames={'py-2 top-8 -left-[180px] w-max'}
    >
      <div className='flex h-fit w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none'>
        <div className='ml-4 mt-3'>
          <div className='flex items-center gap-2'>
            <p className='text-sm font-bold text-navy-700 dark:text-white'>
              👋 Hey, Admin
            </p>{' '}
          </div>
        </div>
        <div className='mt-3 h-px w-full bg-gray-200 dark:bg-white/20 ' />

        <div className='m-4 flex flex-col'>
          <button
            className='w-fit text-base font-medium text-red-500 hover:text-red-700'
            onClick={signOutAdminHandler}
          >
            Sign Out
          </button>
        </div>
      </div>
    </Dropdown>
  );
};

export default NavbarDropdown;

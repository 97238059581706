import { AxiosResponse } from 'axios';

import { axiosPrivate, axiosPublic } from './axios';

export async function signUp({
  email,
  password,
  confirmPassword,
  adminCode,
}: ISignUp) {
  const response: AxiosResponse<IAccount> = await axiosPublic.post(
    '/admin/signup',
    {
      email,
      password,
      confirmPassword,
      adminCode,
    },
    {
      withCredentials: true,
    }
  );
  return response.data;
}

export async function signIn({ email, password }: ISignIn) {
  const response: AxiosResponse<IAccount> = await axiosPublic.post(
    '/admin/signin',
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  );
  return response.data;
}

export async function signOut() {
  const response: AxiosResponse<ISignOut> = await axiosPrivate.post(
    '/signout',
    {}
  );
  return response.data;
}

export async function refreshToken() {
  const response: AxiosResponse<IRefreshToken> = await axiosPublic.get(
    '/refresh',
    {
      withCredentials: true,
    }
  );
  return response.data;
}

export async function fetchProducts() {
  const response: AxiosResponse<IProductsResponse> =
    await axiosPublic.get('/products');

  return response.data;
}

export async function postProduct({
  title,
  description,
  price,
  category_id,
  discount_percentage,
  stock_quantity,
  image_url,
}: IProduct) {
  const response: AxiosResponse<IProductResponse> = await axiosPrivate.post(
    '/products',
    {
      title,
      description,
      price: price.toString(),
      category_id: category_id.toString(),
      discount_percentage: discount_percentage.toString(),
      stock_quantity: stock_quantity.toString(),
      image_url,
    },
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
  return response.data;
}

export async function updateProduct(editedProduct: IProduct) {
  const response: AxiosResponse<IProductResponse> = await axiosPrivate.put(
    `/products/${editedProduct?.id}`,
    editedProduct,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data;
}

export async function deleteProduct(productId: number) {
  const response: AxiosResponse<IDeleteResponse> = await axiosPrivate.delete(
    `/products/${productId}`
  );
  return response.data;
}

export async function fetchCategories() {
  const response: AxiosResponse<ICategoriesResponse> =
    await axiosPublic.get('/categories');
  return response.data;
}

export async function postCategory({ category_name }: ICategory) {
  const response: AxiosResponse<ICategoryResponse> = await axiosPrivate.post(
    '/categories',
    { category_name }
  );
  return response.data;
}

export async function updateCategory({ id, category_name }: ICategory) {
  const response: AxiosResponse<ICategoryResponse> = await axiosPrivate.put(
    `/categories/${id}`,
    {
      category_name,
    }
  );

  return response.data;
}

export async function deleteCategory(categoryId: number) {
  const response: AxiosResponse<IDeleteResponse> = await axiosPrivate.delete(
    `/categories/${categoryId}`
  );
  return response.data;
}

export async function fetchUsers() {
  const response: AxiosResponse<IUsersResponse> =
    await axiosPrivate.get('/users');
  return response.data;
}

export async function fetchUser(userId: number) {
  const response: AxiosResponse<IUserResponse> = await axiosPrivate.get(
    `/users/${userId}`
  );
  return response.data;
}

export async function updateUser(editedUser: IUser) {
  const response: AxiosResponse<IUserResponse> = await axiosPrivate.put(
    `/users/${editedUser?.id}`,
    editedUser
  );
  return response.data;
}

export async function deleteUser(userId: number) {
  const response: AxiosResponse<IDeleteResponse> = await axiosPrivate.delete(
    `/users/${userId}`
  );
  return response.data;
}

export async function fetchAllOrders(
  status: 'PENDING_PAYMENT' | 'PAID' | 'CANCELED'
) {
  const response: AxiosResponse<IOrdersResponse> = await axiosPrivate.get(
    `/transactions/${status}`
  );
  return response.data;
}

import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

import { useStore } from 'store/store';

import useInputState from 'hooks/useInputState';

export default function SignIn() {
  const { input, setInput, onChangeInputHandler } = useInputState({
    inputState: {
      email: '',
      password: '',
    },
  });
  const navigate = useNavigate();

  const { email, password } = input;

  const { signInHandler, authStatus, errorMessageAuth } = useStore(
    (state) => state
  );

  useEffect(() => {
    if (authStatus === 'rejected') {
      errorMessageAuth?.forEach((error) =>
        toast.error(error, { duration: 1500 })
      );
      return;
    }

    if (authStatus === 'fulfilled') {
      navigate('/admin/dashboard', { replace: true });
      setInput({ email: '', password: '' });
    }
  }, [authStatus, errorMessageAuth, navigate, setInput]);

  const signInSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!email || !password) {
      toast.error('Please fill in all fields', { duration: 1500 });
      return;
    }

    signInHandler({ email, password });
  };

  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />
      <div className='mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start'>
        {/* Sign in section */}
        <div className='mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]'>
          <h4 className='mb-2.5 text-4xl font-bold text-navy-700 dark:text-white'>
            Sign In
          </h4>
          <p className='mb-9 ml-1 text-base text-gray-600'>
            Enter your email and password to sign in!
          </p>
          <form
            className='flex flex-col gap-y-4'
            onSubmit={signInSubmitHandler}
          >
            {/* Email */}
            <FormControl>
              <FormLabel fontSize='sm' fontWeight='normal'>
                Email address*
              </FormLabel>
              <Input
                type='email'
                placeholder='mail@example.com'
                name='email'
                onChange={onChangeInputHandler}
                value={email}
              />
            </FormControl>

            {/* Password */}
            <FormControl>
              <FormLabel fontSize='sm' fontWeight='normal'>
                Password*
              </FormLabel>
              <Input
                type='password'
                placeholder='Min. 8 characters'
                name='password'
                onChange={onChangeInputHandler}
                value={password}
              />
            </FormControl>
            <button
              type='submit'
              className='linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'
            >
              Sign In
            </button>
          </form>
          <div className='mt-4'>
            <span className=' text-sm font-medium text-navy-700 dark:text-gray-600'>
              Not registered yet?
            </span>
            <Link
              to='/auth/sign-up'
              className='ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white'
            >
              Create an account
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

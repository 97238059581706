import { Button, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { HiPencil, HiTrash } from 'react-icons/hi';

import { useStore } from 'store/store';

import ModalCreateProduct from './ProductForm';

const AllProductPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    products,
    getProductsHandler,
    deleteProductHandler,
    addToEditProduct,
  } = useStore((state) => state);

  useEffect(() => {
    getProductsHandler();
  }, [getProductsHandler]);

  const deleteOneProductHandler = (productId: number) => {
    deleteProductHandler(productId);
  };

  const editProductHandler = (product: IProduct) => {
    onOpen();
    addToEditProduct(product);
  };

  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />
      <div className='m-4 mt-3 grid h-full grid-cols-1 gap-5'>
        <div className='col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2'>
          <div className='flex flex-col gap-y-6'>
            <div className='flex flex-row items-center justify-between'>
              <h4 className='ml-1 text-2xl font-bold text-navy-700 dark:text-white'>
                All Products
              </h4>
              <Button colorScheme={'blue'} onClick={onOpen}>
                {' '}
                + Create Product
              </Button>
            </div>

            <section>
              <ul className='flex flex-col gap-y-6'>
                {products?.map((product) => (
                  <li
                    key={product?.id}
                    className='w-full rounded-md ring-1 ring-offset-blueSecondary'
                  >
                    <figure className='flex flex-row items-center justify-between'>
                      <div className='flex flex-row items-center gap-x-4'>
                        <img
                          src={product?.image_url as string}
                          alt={product?.title}
                          className='h-32 w-32 rounded-l-md'
                        />
                        <div className='flex flex-col gap-y-1'>
                          <figcaption className='text-base font-medium dark:text-white'>
                            {product?.title}
                          </figcaption>
                          <span className='text-sm font-medium dark:text-gray-200'>
                            {Intl.NumberFormat('id-ID', {
                              style: 'currency',
                              currency: 'IDR',
                            }).format(+product?.price)}
                          </span>
                          <span className='text-sm font-medium text-gray-700/75'>
                            {product?.category?.category_name}
                          </span>
                          <span className='text-sm font-medium text-gray-700/75'>
                            discount: {product?.discount_percentage}%
                          </span>
                        </div>
                      </div>
                      <div className='m-6 flex flex-row items-center gap-x-4'>
                        <button
                          onClick={editProductHandler.bind(null, product)}
                        >
                          <HiPencil className='text-xl text-gray-800 dark:text-gray-100' />
                        </button>
                        <button
                          onClick={deleteOneProductHandler.bind(
                            null,
                            product?.id
                          )}
                        >
                          <HiTrash className='text-xl text-red-600 dark:text-red-400' />
                        </button>
                      </div>
                    </figure>
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </div>
      </div>
      <ModalCreateProduct isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default AllProductPage;

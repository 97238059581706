import { useState } from 'react';
import Calendar from 'react-calendar';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import Card from 'components/card';

import 'react-calendar/dist/Calendar.css';
import 'assets/css/MiniCalendar.css';

const MiniCalendar = () => {
  const [dateValue, setDateValue] = useState<Value>(new Date());

  const dateValueHandler = (value: Value) => {
    setDateValue(value);
  };

  return (
    <div>
      <Card extra='flex w-full h-full flex-col px-3 py-3'>
        <Calendar
          onChange={(value) => dateValueHandler(value)}
          value={dateValue}
          prevLabel={<MdChevronLeft className='ml-1 h-6 w-6 ' />}
          nextLabel={<MdChevronRight className='ml-1 h-6 w-6 ' />}
          view={'month'}
          className='mx-auto w-full'
        />
      </Card>
    </div>
  );
};

export default MiniCalendar;

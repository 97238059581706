import { AxiosError } from 'axios';
import { StateCreator } from 'zustand';

import { fetchAllOrders } from 'api/api';

import { PAID } from 'utils/constant';

export interface IOrderSlice {
  orders: IOrders[];
  limitOrder: number;
  skipOrder: number;
  totalOrder: number;
  orderStatus: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  errorMessageOrder: string[];
  successMessageOrder: string;
  getOrdersHandler: () => Promise<void>;
  updateOrderHandler: (_order: unknown) => Promise<void>;
  deleteOrderHandler: (_transactionId: string) => Promise<void>;
}

export const orderSlice: StateCreator<IOrderSlice, [], [], IOrderSlice> = (
  set
) => ({
  orders: [],
  limitOrder: 0,
  skipOrder: 0,
  totalOrder: 0,
  orderStatus: 'idle',
  errorMessageOrder: [],
  successMessageOrder: '',
  getOrdersHandler: async () => {
    try {
      set({
        orderStatus: 'pending',
        errorMessageOrder: [],
        successMessageOrder: '',
      });

      const { status, transaction } = await fetchAllOrders(PAID);
      if (status === 'success' && transaction) {
        set({
          orderStatus: 'fulfilled',
          orders: transaction,
          limitOrder: transaction.length,
          totalOrder: transaction.length,
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        set({
          orderStatus: 'rejected',
          errorMessageOrder: error.response?.data.message,
        });
      }
    }
  },
  updateOrderHandler: async () => {},
  deleteOrderHandler: async () => {},
});

import {
  MdHome,
  MdLock,
  MdOutlineCategory,
  MdOutlineInbox,
  MdOutlineShoppingBag,
  MdPerson,
} from 'react-icons/md';

import AllProductPage from 'pages/admin/AllProducts';
import DataTables from 'pages/admin/Category';
import MainDashboard from 'pages/admin/MainDashboard';
import OrderPage from 'pages/admin/Order';
import UserPage from 'pages/admin/User';
import SignIn from 'pages/auth/SignIn';
import SignUp from 'pages/auth/SignUp';

const routes = [
  {
    name: 'Main Dashboard',
    layout: '/admin',
    path: 'dashboard',
    icon: <MdHome className='h-6 w-6' />,
    component: <MainDashboard />,
  },
  {
    name: 'Orders',
    layout: '/admin',
    path: 'order',
    icon: <MdOutlineShoppingBag className='h-6 w-6' />,
    component: <OrderPage />,
  },
  {
    name: 'Products',
    layout: '/admin',
    path: 'products',
    icon: <MdOutlineInbox className='h-6 w-6' />,
    component: <AllProductPage />,
    secondary: false,
  },
  {
    name: 'Categories',
    layout: '/admin',
    icon: <MdOutlineCategory className='h-6 w-6' />,
    path: 'category',
    component: <DataTables />,
  },
  {
    name: 'Users',
    layout: '/admin',
    path: 'user',
    icon: <MdPerson className='h-6 w-6' />,
    component: <UserPage />,
  },
];

export const routesAuth = [
  {
    name: 'Sign In',
    layout: '/auth',
    path: 'sign-in',
    icon: <MdLock className='h-6 w-6' />,
    component: <SignIn />,
  },
  {
    name: 'Sign Up',
    layout: '/auth',
    path: 'sign-up',
    icon: <MdLock className='h-6 w-6' />,
    component: <SignUp />,
  },
];
export default routes;
